main{
  flex:5; /* 1:5 proportion built from parent flex */
  display:flex;
  justify-content:center;
  align-items:center;
}

main > div {
  font-size:3rem;
}

