.talkie{
  display:flex;
  justify-content:center;
  margin:2rem 0;
  /*-webkit-user-select: none;*/
}

.talkie img:active {
  transform: scale(0.9, 0.9);
  
  /*-webkit-touch-callout does not 'speak' when tapped, 
    this will cause user frustration, needs to respond immediately*/
    /*
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    user-select:none;
    */
}