.user{
  display:flex;
  flex-direction:column;
  padding:1rem 0.5rem;
  align-items:center;
}

.user .icon{
  font-size: 1.5rem;
  width:4rem;
  height:4rem;
  background-color: #000000;
  border-radius: 3rem;
  display:flex;
  align-items:center;
  justify-content:center;
  margin:0.5rem;
  color:whitesmoke;
}

.user .firstname{
  font-size: 1.75rem;
}

.user div{
  display:flex;
  flex-direction:column;
}

.user hr{
  margin:2rem 0;
  width:90%;
}
